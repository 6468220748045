import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../services/UserService';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  public searchForm=new FormGroup({search:new FormControl('')});
  constructor(public userService:UserService,private router:Router) { }

  ngOnInit() {
  
  }
  ionViewWillEnter(){
    this.userService.setUsers();
  }

  viewUser(user){
    console.log('USER',user);
    this.router.navigateByUrl('/users/view/'+user.id,{state:user});
  }

}
