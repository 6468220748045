import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { SearchProductComponent } from '../search-product/search-product.component';
import { Api } from '../services/Api';
import { Cart } from '../services/Cart';
import { ProductService } from '../services/ProductService';

@Component({
  selector: 'app-product-barcode-dashboard',
  templateUrl: './product-barcode-dashboard.component.html',
  styleUrls: ['./product-barcode-dashboard.component.scss'],
})
export class ProductBarcodeDashboardComponent implements OnInit {
  @Input() searchForm:FormGroup;
  constructor(public cart:Cart,public api:Api,public productService:ProductService,private router:Router,private modalCtrl:ModalController) { }

  ngOnInit() {}
  charge(){
    this.router.navigate(['/charge'])
  }

  async searchProducts(){
    const modal = await this.modalCtrl.create({
      component:SearchProductComponent
    });
    await modal.present();
    modal.onDidDismiss().then(data=>{
      console.log('THE DATA',data);
        if(data?.data){
          this.api.activeCart.addToCart(data.data,true);
        }
    });
    
  }
}
