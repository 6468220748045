import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { Product } from '../models/Product';
import { Api } from './Api';
import { LineItem } from '../models/LineItem';
import { ModalController } from '@ionic/angular';
import { EditCartProductComponent } from '../edit-cart-product/edit-cart-product.component';

import { Settings } from '../models/Settings';
import { SettingsService } from './SettingsService';

@Injectable()
export class ProductService {
  public products:Array<LineItem>=[];
  public productsList:Array<LineItem>=[];
  constructor(private api:Api,private modalCtrl:ModalController,private settingService:SettingsService){
      
  }
  async setProducts(searchQuery?:string){
    this.products=[];
    let isOnline = await this.settingService.isOnline();
     if( isOnline ){
        
          this.getProductsFromApi(searchQuery).subscribe((products:Array<any>)=>{
            this.products = products.map((p)=>{
              return new LineItem(p.id,p.name,p.description,p.price,p.unit,p.type,p.barcode,p.available);
            });
            this.productsList = this.products;
          });
     }
  }
  getProductsFromApi(searchQuery?:string){
    if(searchQuery == undefined){
        searchQuery="";
    }
    return this.api.processWithToken("/products",{q:searchQuery},'get');
  }

  public filteredProducts(searchElement?:any){
    let search = searchElement.value;
    console.log('SEARCH QUERY',search);
    if(search==""){
        return this.setProducts();
    }
    if(search!=""){
      let result=this.products.filter((p)=>{
        let barcode = p.barcode ? p.barcode:'';
        let searchString = p.name + barcode
        return searchString.toLowerCase().indexOf(search.toLowerCase())!=-1;
      });
      if(result.length == 0){
          this.setProducts(search);
      }

    
      if(result.length == 1){
        let product = result[0];
        console.log('THE PRODUCT',product);
        /*
        let barcode = product.barcode ? product.barcode : '';
         if(search.toLowerCase() == barcode){
           setTimeout(()=>{
            this.api.activeCart.addToCart(product);
            searchElement.setValue('');
          },500);
         
         }
         */
      }
      this.productsList=result;
      return result;
      
    }
    return this.products;
  }
  getProductByBarcode(barcode:string){
    let index=this.products.findIndex((p)=>p.barcode == barcode);
    if(index > -1){
      return this.products[index];
    }
    return false;
  }

  


  async editAndAdd(p){
    let modal = await this.modalCtrl.create({
      component:EditCartProductComponent,
      componentProps:{
        p:p
      }
    });
  
    modal.onDidDismiss().then((res:any)=>{
      if(res.data && res.data.hasOwnProperty('product')){
        console.log("THE PRODUCT",res.data.product);
        this.api.activeCart.addToCart(res.data.product,false);
      }
    });
    
    return await modal.present();
  }

  
  
  async getProductsFromDb(){
   //return getRepository(Product).find();
  }

  async saveProductToDb(product_id,formData){
      product_id = Math.random();
      let p = new Product(product_id,formData.name,'',formData.price,formData.unit,formData.price,formData.barcode);
      /*
      const productRep = getManager();
      return productRep.save(p,{transaction:false});
      */
  }

  async saveProduct(product_id,formData){
    let isOnline = await this.settingService.isOnline();
      if(isOnline){
        return this.saveProductToApi(product_id,formData);
      }else{
        return this.saveProductToDb(product_id,formData);
      }
  }
  saveProductToApi(product_id,formData){
    if( product_id !=0 ){
      return this.api.processWithToken('/products/'+product_id,formData,'put').toPromise();
    }
    return this.api.processWithToken('/products',formData).toPromise();
  }

  async getProductById(id){
    
  }

  getProductByIdFromApi(id){
    return this.api.process("/products/"+id,{},'get').toPromise();
  }
}