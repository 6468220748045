import { Component, OnInit, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { NewCustomerModalComponent } from '../new-customer-modal/new-customer-modal.component';
import { Api } from '../services/Api';
@Component({
  selector: 'app-customer-modal',
  templateUrl: './customer-modal.component.html',
  styleUrls: ['./customer-modal.component.scss'],
})
export class CustomerModalComponent implements OnInit {
  public customers=[];
  public selectedCustomer:any=false;
  public search:string="";
  constructor(private modalCtrl:ModalController,private api:Api){  
  }
  ngOnInit() {
      this.setCustomers();
  }
  
  selectCustomer(c:any){
    this.selectedCustomer = c;
    console.log('selected Customer2',c);
    this.modalCtrl.dismiss(c);
  }

  openNewCustomerModal(){
    this.modalCtrl.create({component:NewCustomerModalComponent});
  }

  setCustomers(){
    this.api.processWithToken('/customers',{q:this.search},'get').subscribe((customers:any)=>{
      this.customers = customers;
    },(error)=>{
        alert('Can not get customers');
    });
  }

}