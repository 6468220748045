import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import {Product} from '../models/Product';
import { Settings } from '../models/Settings';
@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private config:Settings;
  private storage:Storage;
  constructor(private storageService:Storage){
    this.init();
  }

  async init(){
    this.storage = await this.storageService.create();
    this.getSetting().then((config:Settings)=>{
        this.config = config;
    });
  }
  async getSetting(){
      let setting= await this.storage.get('settings');
      let s = new Settings();
      s.setSetting(setting);
      return s;
  }

  async isOnline(){
    let setting= await this.storage.get('settings');
    return setting && setting.mode == 'online';
  }


}
