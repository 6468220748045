import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
import { SQLite } from '@ionic-native/sqlite/ngx';
import { SQLitePorter } from '@ionic-native/sqlite-porter/ngx';
import { LoginComponent } from './login/login.component';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Api } from './services/Api';
import { SettingsComponent } from './settings/settings.component';
import { Cart } from './services/Cart';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerModalComponent } from './customer-modal/customer-modal.component';
import { CustomersPage } from './customers/customers.page';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { OrdersComponent } from './orders/orders.component';
import { LoginGuard } from './services/LoginGuard';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { EditCartProductComponent } from './edit-cart-product/edit-cart-product.component';
import { KeypadComponent } from './keypad/keypad.component';
import { ChargeComponent } from './charge/charge.component';
import { CartlistComponent } from './cartlist/cartlist.component';
import { CartInfoComponent } from './cart-info/cart-info.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { SharedModule } from './shared/shared.module';
import { CustomerTabsComponent } from './customer-tabs/customer-tabs.component';
import { ProductService } from './services/ProductService';
import { ProductSelectionDashboardComponent } from './product-selection-dashboard/product-selection-dashboard.component';
import { ProductBarcodeDashboardComponent } from './product-barcode-dashboard/product-barcode-dashboard.component';
import { SearchProductComponent } from './search-product/search-product.component';
import { SettingsService } from './services/SettingsService';
import { UsersComponent } from './users/users.component';
import { UserService } from './services/UserService';
import { ViewUserComponent } from './view-user/view-user.component';
import { CustomerService } from './services/CustomerService';
import { Storage } from '@ionic/storage-angular';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SettingsComponent,
    DashboardComponent,
    CustomersPage,
    CustomerModalComponent,
    SearchProductComponent,
    OrdersComponent,
    KeypadComponent,
    ChargeComponent,
    EditCartProductComponent,
    CartlistComponent,
    CartInfoComponent,
    CustomerTabsComponent,
    ReceiptComponent,
    ProductSelectionDashboardComponent,
    ProductBarcodeDashboardComponent,
    UsersComponent,
    ViewUserComponent
  ],

  entryComponents: [CustomerModalComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
 
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    SQLite,
    SQLitePorter,
    Api,
    ProductService,
    Storage ,
    LoginGuard,
    InAppBrowser,
    Cart,
    SettingsService,
    UserService,
    CustomerService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HttpClient
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
