
export class Dari{
    public static words={
        appName:'SALES',
        welcome_message:"خوش آمدید",
        supportContactNumber:'SUPPORT: +93 70 230 2030',
        products:'اجناس',
        poolTables:'Pool Tables',
        users:"کاربران",
        productInfo:"معلومات جنس",
        save:'ثبت',
        dari:'دری',
        login:"خروج",
        new_user:"کاربر جدید",
        user:{
            username:"اسم کارمند",
            password:"پاسورد"
        },
        userInfo:"معلومات کاربر",
        pashto:'پښتو',
        english:'انگلیسی',
        settings:"تنظیمات",
        select_language:'لطفا زبان را انتخاب نمائید',
        app_mode:"لطفا نوع ثبت معلومات را انتخاب نمائید",
        online:"آنلاین",
        offline:'بیدون انترنت',
        end_point_address:"آدرس سرور",
        enter_end_point_address:"آدرس سرور را اینجا تایپ کنید http:// or https://....",
        messages:{
            invalidUrl:"لطفا آدرس درست سروری را تایپ کنید!",
            problem:"یک مشکل رخ داد لطفا دوباره کوشش کنید",
            payment_success:"پرداخت بصورت موفقانه صورت گرفت!",
            delete_confirmation:"آیا میخواهیم این ریکارد را حذف کنید؟"
        },
        product:{
            name:"نام جنس",
            price:"قیمت فی واحد",
            type:"نوعیت جنس",
            unit:"واحد",
            barcode:"بارکود",
            info:"معلومات جنس",
            stock:"موجودی اجناس"
        },
        date:"تاریخ",
        invoiceId:"شماره بل",
        customer_name:"مشتری",
        total:"مجموع",
        currency:"افغانی",
        select_currency:"انتخاب واحد پولی",
        filter_by_customer:"فلتر به اساس مشتری",
        search:"جستجو",
        from:"از",
        to:"الی",
        Deactivate:"غیر فعال",
        Activate:"فعال",
        selectDate:"انتخاب تاریخ",
        ordersActions:{
            print:"پرنت",
            edit:"تغیرات",
            cancel:"لغوه",
            receivePayment:"پرداخت قرضه"
        },
        cartItemActions:{
            delete:"حذف",
            edit:"تغیرات",
        },
        actions:"عملیات",
        dashboard:"فرمایشات",
        customers:"مشتریان",
        orders:"فروشات",
        payment_type:"نوع پرداخت",
        cash:"پول نقد",
        account:"قرضه",
        grandTotal:"مجموع",
        tenderedAmount:"مقدار پرداخت",
        change:"پول میده",
        new_customer:"مشتری جدید",
        new_product:"جنس جدید",
        dashboardType:"نوعیت سیستم فروشات",
        dashboardType_1:"فروشات به اساس انتخاب جنس و بارکود",
        dashboardType_2:"فروشات تنها به اساس بارکود",
        dashboardType_3:"فروشات به اساس میز",
        customer:{
            info:"معلومات مشتری",
            statement:"حسابات مشتری",
            name:"نام",
            phone1:"شماره تلیفون اولی",
            phone2:"شماره تلیفون دومی",
            email:"ایمیل",
        },
        Save:"ثبت",
        Back:"برگشت",
        description:"مشخصات",
        price:"قیمت",
        qty:"مقدار",
        physical:"جنس",
        pool:"میز بلیارد",
        each:"فی عدد",
        hour:"فی ساعت",
        kg:"فی کیلو",
        amount:"مبلغ",
        note:"نوت",
        sold:"تعداد فروش",
        available:"تعداد موجود",
        balance:"بقایه",
        getReport:"ایجاد راپور",
        account_statement:"حساب باقی داری",
        selectCustomer:"انتخاب مشتری",
        accountPayment:"پرداخت پول",
        invoicePayment:"پرداخت",
        charge:"قرضه",
        paymentType:"نوع پراخت",
        errors:{
            try_again:"یک مشکل رخ داد لطفا دوباره کوشش کنید",
            invoice_payment_selected:"تنها پرداخت به اساس بل انتخاب شده میتواند"
        },
        invoiceStatus:"حالت بل",
        paid:"پرداخت شده",
        not_paid:"قرضه",
        action:"عملیات",
        remaining_balance:"مقدار قرضداری",
        advance_balance:"مقدار پیش پرداخت",
        confirm:"ثبت",
        stocks:"لست موجودی",
        totalInStock:"تعداد مجموعی",
        totalStockValue:"قیمت مجموعی اجناس",
        totalStockSold:"مجموع فروشات",
        sale_confirmation_message:"آیا بل تکمیل است؟",
        delete:"حذف",
        delete_confirmation_message:"آیا میخواهید این را حذف نمائید!",
        newStock:"خرید جدید",
        stock:"خرید",
        companyName:"اسم شرکت",
        yes:"بلی",
        no:"نخیر",
        receiptPrinter:"آیا سیستم قابلیت رسید پرنتر را دارد؟",
        receipt:"رسید",
        printReceipt:"چاپ رسید",
        printInvoice:"چاپ بل",
        nextOrder:"فرمایش جدید",
        payment:"پرداخت پول"
    }
}