import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Api } from 'src/app/services/Api';
import { UserService } from '../services/UserService';
@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss'],
})

export class ViewUserComponent implements OnInit {
  public userForm = new FormGroup({
    id:         new FormControl(''),
    username:       new FormControl('',Validators.required),
    password: new FormControl('',Validators.required),
  });
  private user_id=0;
  constructor(private router:Router,private activatedroute:ActivatedRoute,private userService:UserService) { 
 
    let user=this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state;
    if(user && user.hasOwnProperty('id')){
      this.setUserForm(user);
      this.user_id = user.id;
  
    }
  }

  ngOnInit() {}

  save(redirect=true){
    this.userService.saveUser(this.user_id,this.userForm.value).then((product)=>{
      if(redirect){
          this.router.navigate(['/users']);
      }
    });
  }


  private setUserForm(user){
    this.userForm = new FormGroup({
      id:         new FormControl(user.id),
      username:       new FormControl(user.username,Validators.required),
      password:       new FormControl(user.password,Validators.required),
    });
  }



}

