import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Api } from 'src/app/services/Api';

@Component({
  selector: 'app-account-payment',
  templateUrl: './account-payment.component.html',
  styleUrls: ['./account-payment.component.scss'],
})
export class AccountPaymentComponent implements OnInit {
  public paymentType          ="invoice";
  public invoiceButtonColor   ="secondary";
  public chargeButtonColor    ="secondary";
  public paymentForm:FormGroup;
  @Input() customer_id:any;
  @Input() amount:any="";
  @Input() reference_no:any="";
  constructor(private api:Api,private modalCtrl:ModalController,private translator:TranslateService) { }

  ngOnInit() {
    this.selectPaymentType('invoice');
    this.paymentForm = new FormGroup({
      amount:new FormControl(this.amount,Validators.required),
      note: new FormControl(''),
      invoice_id: new FormControl(this.reference_no)
    });
  }

  selectPaymentType(type:string){
    if(this.reference_no!="" && type!='invoice'){
      this.translator.get('errors.invoice_payment_selected').subscribe((message)=>{
        alert(message);
      });
      return false;
    }
    this.paymentType = type;
    if(this.paymentType=='invoice'){
        this.chargeButtonColor='secondary';
        this.invoiceButtonColor='success';
    }else{
        this.chargeButtonColor='success';
        this.invoiceButtonColor='secondary';
    }
  }

  processPayment(){
    let data = this.paymentForm.value;
    console.log('PAYMENT FORM',data);
    if(this.paymentType=='charge'){
      data.amount = - data.amount;
    }
    this.api.processWithToken("/customers/adjustAccount/"+this.customer_id,data,'get').subscribe((res)=>{
        this.modalCtrl.dismiss({payment:res});
    },()=>{
        this.translator.get('errors.try_again').subscribe((message)=>{
          alert(message);
        });
    });
  }

}
