import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ChargeComponent } from './charge/charge.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { OrdersComponent } from './orders/orders.component';
import { LoginGuard } from './services/LoginGuard';
import { SettingsComponent } from './settings/settings.component';
import { UsersComponent } from './users/users.component';
import { ViewUserComponent } from './view-user/view-user.component';
const routes: Routes = [
  {path: "", redirectTo: "/login", pathMatch: "full" },
  {
    path: 'login',
    component:LoginComponent,
  },
  {
    path: 'dashboard',
    component:DashboardComponent,
    canActivate:[LoginGuard]
  },
  {
    path: 'charge',
    component:ChargeComponent,
    canActivate:[LoginGuard]
  },
  {
    path: 'settings',
    component:SettingsComponent,
  },
  {
    path: 'orders',
    canActivate:[LoginGuard],
    component:OrdersComponent,
  },
  {
    path: 'users',
    canActivate:[LoginGuard],
    component:UsersComponent,
  },
  {
    path: 'users/view/:id',
    canActivate:[LoginGuard],
    component:ViewUserComponent,
  },
  {
    path: 'users/view',
    canActivate:[LoginGuard],
    component:ViewUserComponent,
  },
  {
    path: 'products',
    canActivate:[LoginGuard],
    loadChildren: () => import('./products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'customers',
    canActivate:[LoginGuard],
    loadChildren: () => import('./customers/customers.module').then( m => m.CustomersPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
