import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ChargeComponent } from '../charge/charge.component';
import { EditCartProductComponent } from '../edit-cart-product/edit-cart-product.component';
import { LineItem } from '../models/LineItem';
import { Product } from '../models/Product';
import { ReceiptComponent } from '../receipt/receipt.component';
import { Api } from '../services/Api';
import {Cart} from '../services/Cart';
import { HostListener } from '@angular/core';
import * as onScan from '../../../node_modules/onscan.js';
import { DOCUMENT } from '@angular/common';
import { ProductService } from '../services/ProductService';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public searchForm=new FormGroup({search:new FormControl('')});
  public setting;
  @ViewChild("document") divView: ElementRef;
  constructor(private modalCtrl:ModalController,private router:Router,private api:Api,private translate:TranslateService,@Inject(DOCUMENT) private myDocument: Document,public productService:ProductService) { 
    try{
      onScan.attachTo(this.myDocument);
    }catch(error){
      console.log('error');
    }
    
  }
  @HostListener('document:scan', ['$event'])
  onScanEvent(e){
   let barcode=e.detail.scanCode;
   let product = this.productService.getProductByBarcode(barcode);
   console.log('SCANNED PRODUCT',product);
   if(product){
     this.api.activeCart.addToCart(product,true);
     this.searchForm.get('search').setValue('...');
     setTimeout(()=>{
      this.searchForm.get('search').setValue('');
     },500);
     
   
   }
  }
  ngOnInit() {
  //  this.api.activeCart.reset();
    this.productService.setProducts();
  }




 
}
