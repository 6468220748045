import { Injectable } from '@angular/core';
import { Api } from './Api';
import { User } from '../models/User';
import { ModalController } from '@ionic/angular';
import { SettingsService } from './SettingsService';

import { DbService } from './db.service';

@Injectable()
export class UserService {
  public users:Array<User>=[];
  public usersList:Array<User>=[];
  constructor(private api:Api,private modalCtrl:ModalController,private settingService:SettingsService){
      
  }
  async setUsers(searchQuery?:string){
    this.users=[];
    let isOnline = await this.settingService.isOnline();
     if( isOnline ){
          this.getUsersFromApi(searchQuery).subscribe((users:Array<any>)=>{
            this.users = users.map((u)=>{
              let user= new User();
                  user.id = u.id;
                  user.username = u.username;
                  return user;
            });
            this.usersList = this.users;
          });
     }
  }
  getUsersFromApi(searchQuery?:string){
    if(searchQuery == undefined){
        searchQuery="";
    }
    return this.api.processWithToken("/users",{q:searchQuery},'get');
  }

  public filteredUsers(searchElement?:any){
    let search = searchElement.value;
    console.log('SEARCH QUERY',search);
    if(search==""){
        return this.setUsers();
    }
    if(search!=""){
      let result=this.users.filter((u)=>{
        let searchString = u.username;
        return searchString.toLowerCase().indexOf(search.toLowerCase())!=-1;
      });
      if(result.length == 0){
          this.setUsers(search);
      }
      this.usersList=result;
      return result;
      
    }
    return this.users;
  }

  async getProductsFromDb(){
   //return getRepository(User).find();
  }

  async saveUser(user_id,formData){
  
    let isOnline = await this.settingService.isOnline();
    console.log('IS ONLINE',isOnline);
    if(isOnline){
      return this.saveUserToApi(user_id,formData);
    }
    return this.saveUserToDb(user_id,formData);
  }
  async saveUserToDb(user_id,formData){
    let u = new User();
    if(!user_id || user_id == 0){
      user_id = '';
    }else{
      //u.id = user_id;
    }
    u.username = formData.username;
    u.password = formData.password;
    //return u.save({transaction:false});
    //this.getUsersFromDb();
  }

  saveUserToApi(user_id,formData){
    if( user_id !=0 ){
      return this.api.processWithToken('/users/'+user_id,formData,'put').toPromise();
    }
    return this.api.processWithToken('/users',formData).toPromise();
  }

  public getUsersFromDb(){
    //return getRepository(User).find();
  }
  async hasNoUser(){
    /*
    let user = await getRepository(User).findOne();
    if(user){
      return false;
    }
    return true;
    */
   return true;
  }

  async loginByDb(formValue){
    /*
    console.log('formValue',formValue);
    let result = await this.storage.remove('userInfo');
    let user = await getRepository(User).findOne({
      where:{username:formValue.username,password:formValue.password}
    });

    if(user && user.hasOwnProperty('id')){
      console.log('USER FOUND',user);
      this.storage.setItem('userInfo',user);
      return true;
    }
    
    return false;
    */
   }

}