import { Component, OnInit } from '@angular/core';
import { Api } from '../services/Api';

@Component({
  selector: 'app-customer-tabs',
  templateUrl: './customer-tabs.component.html',
  styleUrls: ['./customer-tabs.component.scss'],
})
export class CustomerTabsComponent implements OnInit {

  constructor(private api:Api) { }

  ngOnInit() {}

  addTab(){
    this.api.tabs.push(this.api.tabs.length+1);
  }

}
