import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { KeypadComponent } from '../keypad/keypad.component';
import { ReceiptComponent } from '../receipt/receipt.component';
import { Api } from '../services/Api';
import { Cart } from '../services/Cart';

@Component({
  selector: 'app-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.scss'],
})
export class ChargeComponent implements OnInit {

  constructor(private modalCtrl:ModalController,private translate:TranslateService,public api:Api,private router:Router) { }

  ngOnInit() {}
  addAmount(amount){
    this.api.activeCart.tendered_amount  = this.api.activeCart.tendered_amount + amount;
    this.api.activeCart.updateChange();
  }
  async  openReceiptModal(invoice){
 
    const modal = await this.modalCtrl.create({
      component: ReceiptComponent,
      componentProps: {invoice:invoice}
    });
    let m=await modal.present();

    modal.onDidDismiss().then( ()=>{
      this.router.navigate(['/dashboard']);
    });
    return m;
  

}
  confirm(){
  
    this.translate.get('sale_confirmation_message').subscribe((message)=>{
      let c = confirm(message);
      if(!c){
        return false;
      }
      let transaction={
        reference_no:this.api.activeCart.reference_no,
        customer_name:this.api.activeCart.selectedCustomer.name,
        customer_id:this.api.activeCart.selectedCustomer.id,
        payment_type:this.api.activeCart.paymentType,
        change:this.api.activeCart.change,
        tendered_amount:this.api.activeCart.tendered_amount,
        lineItems:this.api.activeCart.getCartItems()
      };
      this.api.processWithToken("/invoices",transaction).subscribe((res:any)=>{
          
          this.openReceiptModal(res);
          this.api.activeCart.reset();
      });
    })
   
  }

  public isDisabled(){
    if(this.api.activeCart.paymentType !='cash'){
      return false;
    }
    if(this.api.activeCart.grandTotal == 0){
      return true;
    }
    return this.api.activeCart.tendered_amount < this.api.activeCart.grandTotal;
  }

  async openKeyPad(fieldName){
    let modal = await this.modalCtrl.create({
      component:KeypadComponent,
      componentProps:{
        "fieldName":fieldName
      }
    });
    modal.onDidDismiss().then((res:any)=>{
      if(res.data && res.data.value){
        if(fieldName == 'amount'){
          this.api.activeCart.tendered_amount=res.data.value;
        }
       
      }
    });
    return await modal.present();
  }
}
