import { Product } from '../models/Product';
import { Settings } from '../models/Settings';

export class LineItem extends Product {
    public isChecked=false;
    public qty:number      = 0;
    public discount = 0;
    
    /**
     * Total without discount
     */
 subTotal(){
      let total = this.price * this.qty;
      return total;
  }

  /**
   * Calculates discount based on type
   */
  discountAmount(){
   //   if(this.discountType == 'amount'){
          return this.discount;
      //}
      let discountAmount = this.subTotal() * (this.discount/100);
      return Number(discountAmount.toFixed(2));
  }


  /**
   * Calculates after discount
   */

   afterDiscount(){
      let total = this.subTotal() - this.discountAmount();
  
      return total;
   }
   
      /**
   * Calculates total Payable 
   */

 total(){
      let total = this.afterDiscount();

      if(false == false){
         //total= total + this.tax();
      }
      return Number(total.toFixed(2));
      
   }


   /**
    * Calculates taxes
    */
   
    tax(){
      return 0;
        if(this.tax){
          //let total = this.afterDiscount() * ( Settings.temp.tax.percentage / 100 );
          //return  Number(total.toFixed(2));
        }
        
        return 0.00;
    
    }

    /**
     * Sets Product image
     */

     setImage(url){
         // this.picture = url;
     }


   increase(){
     console.log(this.qty,"+",1);
      this.qty = Number(this.qty)+1;
    }
  
    decrease(){
      if(this.qty > 1 ){
        this.qty = Number(this.qty)-1;
      }
     
    }

    currentStock(){
      if(this.isStockable){
          return this.current_stock;
      }
      return '....';
    }

    setIsStockable(stockable){
        this.isStockable = stockable;
    }

    currentStockText(){
      return this.currentStock();
    }
  
}