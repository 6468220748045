import { Injectable } from '@angular/core';

import { HttpClient,HttpHeaders } from '@angular/common/http';
import {Settings} from '../models/Settings';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Cart } from './Cart';
import { CustomerModalComponent } from '../customer-modal/customer-modal.component';
import { SettingsService } from './SettingsService';
import { from } from 'rxjs';
import { Storage } from '@ionic/storage';
import { resolve } from 'dns';
@Injectable()
export class Api {
  public endpoint       =   "";
  public token          =   "";
  private httpOptions   = {};
  public settings:Settings;
  public tabs=[];
  public activeCart;
  public selectedTabIndex=0;
  private storage:Storage;
  constructor( private http: HttpClient,private storageService: Storage,private platform:Platform,private translate:TranslateService,private br:InAppBrowser,private modalCtrl:ModalController,private settingService:SettingsService) { 
    this.settings = new Settings();
    console.log('THE SETTINGS',this.settings);
    this.tabs.push(new Cart());
    this.switchCart(0);
    this.init();
  }
  async init() {
    this.storage  = await this.storageService.create();
    const settings = await this.storage.get('settings');
    if(settings){
      this.settings = settings;
    }
   
  }
  public process(action:String,data:any,type:String="post"){
        if(type == 'post'){
            return this.http.post( this.getUrl(action) ,data );
        }

        return this.http.get( this.getUrl(action) ,data );
  }

  public processWithToken(action:String,data:any,type:String="post"){
    this.setHeaders();
    data['lang']= this.translate.currentLang;
    if(type == 'post'){
        return this.http.post( this.getUrl(action) ,data ,this.httpOptions);
    }
    if(type == 'put'){
      return this.http.put( this.getUrl(action) ,data ,this.httpOptions);
    }
    if(type == 'delete'){
      return this.http.delete( this.getUrl(action) ,this.httpOptions);
    }
    let options = Object.assign({},this.httpOptions);
    options['params']=data;
    return this.http.get( this.getUrl(action) ,options);
}

  public getUrl(action){
      return this.settings.endpoint+action;
  }
  
  saveSettings(){
    this.storage.set('settings',this.settings);
    
  }
  public setToken(token:string){
        this.storage.get('settings').then((setting:any)=>{
          setting['token'] = token;
          this.settings = setting;
          this.saveSettings();
        });
        
        this.setHeaders();
  };
  private setHeaders(){
    if(!this.settings || !this.settings.hasOwnProperty('token')){
      return false;
    }
    this.httpOptions={
      headers: new HttpHeaders({
      'Content-Type':  'application/json',
      Authorization: 'Bearer '+this.settings.token
      })
    };
  }
  
  private changeDirection(direction){
    console.log('SETTINGS',this.settings);
    if(this.settings){
      this.settings.direction=direction;
    }else{
      this.settings['direction']=direction;
    }
  
  }
  
  changeLanguage(language){
    if(language == 'en'){
      this.changeDirection('ltr');
    }else{
      this.changeDirection('rtl');
    }
    this.translate.use(language);
  }

  async  isLoggedIn(){
    let isOnline = await this.settingService.isOnline();
    let userInfo={};
    if(isOnline){
        userInfo=this.processWithToken('/userInfo',{},'get');
    }else{
        userInfo = await this.storage.get('userInfo');
    }
    
    if(userInfo && userInfo.hasOwnProperty('id')){
      return true;
    }

    return false;
   
  }

  public printInvoice(reference_no){
    let url=this.getUrl('/invoiceToPdf?reference_no='+reference_no);
    return this.br.create(url);
  }
  public printStatement(customer_id,startDate,endDate){
    let url=this.getUrl('/printStatement/'+customer_id+'?startDate='+startDate+"&endDate="+endDate);
    return this.br.create(url);
  }

  public addTab(){
      this.tabs.push(new Cart());
      this.switchCart(this.tabs.length-1);

  }
  public switchCart(index){
    this.activeCart=this.tabs[index];
    this.selectedTabIndex=index;
  }

  async selectCustomer() {
    
    const modal = await this.modalCtrl.create({
      component:CustomerModalComponent
    });
    await modal.present();
    let c = await modal.onWillDismiss();
    this.activeCart.selectedCustomer = c.data;
    return new Promise((resolve,reject)=>{ return resolve(c.data)});

  }

  async getCount(type){
    return await this.processWithToken('/count/'+type,{},'GET').toPromise();
  }
}