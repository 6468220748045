import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-customer-modal',
  templateUrl: './new-customer-modal.component.html',
  styleUrls: ['./new-customer-modal.component.scss'],
})
export class NewCustomerModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
