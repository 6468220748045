import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Api } from '../services/Api';
import { UserService } from '../services/UserService';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public form:FormGroup;
  constructor(private translate:TranslateService,private platform:Platform,private api:Api,private router: Router,private userService:UserService) { }
  public hasNoUser = false;
  public userForm = new FormGroup({
    id:         new FormControl(''),
    username:       new FormControl('',Validators.required),
    password: new FormControl('',Validators.required),
  });
  ngOnInit() {
    
    this.form= new FormGroup({
      companyName: new FormControl('',Validators.required),
      language: new FormControl('',Validators.required),
      mode:     new FormControl('',Validators.required),
      endpoint: new FormControl(''),
      receiptPrinter: new FormControl('0'),
      dashboardType: new FormControl('0'),
      currency: new FormControl('AFN')
    });
    this.platform.ready().then(()=>{
      
      setTimeout(()=>{
        this.checkHasNoUser();
        console.log('API SETTING 2',this.api.settings);
        if(this.api.settings==undefined){
          return false;
        }
        this.form= new FormGroup({
          companyName: new FormControl(this.api.settings.companyName,Validators.required),
          language: new FormControl(this.api.settings.language,Validators.required),
          mode:     new FormControl(this.api.settings.mode,Validators.required),
          endpoint: new FormControl(this.api.settings.endpoint),
          receiptPrinter: new FormControl(this.api.settings.receiptPrinter),
          dashboardType: new FormControl(this.api.settings.dashboardType),
          currency: new FormControl(this.api.settings.currency)
        });
      },2000);
     
    });
   
  }
  async checkHasNoUser(){
    this.hasNoUser = await this.userService.hasNoUser();
  }
  changeLanguage(){
    let language =this.form.get('language').value;
    this.api.changeLanguage(language);
  }

  isOnlineMode(){
    console.log('MODE',this.form.get('mode').value);
    return this.form.get('mode').value =='online';
  }

  saveSettings(){
    console.log('TEST');
    let formValue = this.form.value;
    console.log('FORM VALUE',formValue);
    if( formValue.mode == 'online' && this.isvalidURL(formValue.endpoint) ==false){
        this.translate.get('messages.invalidUrl').subscribe((translation)=>{
          alert(translation);
        });

        return false;
    }

    console.log("API SETTINGS",this.api.settings);
    this.api.settings.companyName      = formValue.companyName;
    this.api.settings.mode      = formValue.mode;
    this.api.settings.language  = formValue.language;
    this.api.settings.endpoint  = formValue.endpoint;
    this.api.settings.receiptPrinter  = formValue.receiptPrinter;
    this.api.settings.dashboardType  = formValue.dashboardType;
    this.api.settings.currency  = formValue.currency;
    this.api.saveSettings();
    this.router.navigate(['']);
  }
  isvalidURL(str) {
    console.log('STR',str);
    let url:any="";
    try {
      url = new URL(str);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  public saveUser(){
    this.userService.saveUser(undefined,this.userForm.value).then(()=>{
      this.checkHasNoUser();
    });
  }
}
