import { Component, OnInit } from '@angular/core';
import { Api } from '../services/Api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { CustomerService } from '../services/CustomerService';
@Component({
  selector: 'app-customers',
  templateUrl: './customers.page.html',
  styleUrls: ['./customers.page.scss'],
})
export class CustomersPage implements OnInit {
  constructor(private api:Api,private route: ActivatedRoute,private router:Router,public customerService:CustomerService) { 
  }
  public search:string="";
  public totalCustomers=0;
  ngOnInit() {
    this.setCustomerCount();
  }

  async setCustomerCount(){
    let c:any = await this.api.getCount('customers');
    this.totalCustomers = c.count;
  }
  


  async filteredCustomers(){
    console.log('filtering');
    let result = await this.customerService.setCustomers(this.search);
   
    return this.customerService.customers;
  }
  openCustomer(customer){
    this.router.navigateByUrl('customers/view-customer',{state:customer});
  }
  ionViewWillEnter(){
    this.customerService.setCustomers();
  }
}
