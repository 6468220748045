import { Injectable } from '@angular/core';
import { Api } from './Api';
import { Customer } from '../models/Customer';
import { ModalController } from '@ionic/angular';

import { SettingsService } from './SettingsService';

import { DbService } from './db.service';

@Injectable()
export class CustomerService {
  public customers:Array<Customer>=[];
  public customersList:Array<Customer>=[];
  constructor(private api:Api,private modalCtrl:ModalController,private settingService:SettingsService){
      
  }
  async setCustomers(searchQuery?:string){
    this.customers=[];
    let isOnline = await this.settingService.isOnline();
     if( isOnline ){
          this.getCustomersFromApi(searchQuery).subscribe((customers:Array<any>)=>{
            this.customers = customers.map((c)=>{
              let customer= new Customer();
                  customer.id = c.id;
                  customer.name = c.name;
                  customer.email = c.email;
                  customer.phone1 = c.phone1;
                  customer.phone2 = c.phone2;

                  return customer;
            });
            this.customersList = this.customers;
          });
     }
  }
  getCustomersFromApi(searchQuery?:string){
    if(searchQuery == undefined){
        searchQuery="";
    }
    return this.api.processWithToken("/customers",{q:searchQuery},'get');
  }

  public filteredCustomers(searchElement?:any){
    let search = searchElement.value;
    console.log('SEARCH QUERY',search);
    if(search==""){
        return this.setCustomers();
    }
    if(search!=""){
      let result=this.customers.filter((u)=>{
        let searchString = u.name+" "+u.email+" "+u.phone1;
        return searchString.toLowerCase().indexOf(search.toLowerCase())!=-1;
      });
      if(result.length == 0){
          this.setCustomers(search);
      }
      this.customersList=result;
      return result;
      
    }
    return this.customers;
  }

  async saveCustomer(user_id,formData){
  
    let isOnline = await this.settingService.isOnline();
    if(isOnline){
      return this.saveCustomerToApi(user_id,formData);
    }
    return this.saveCustomerToDb(user_id,formData);
  }
  async saveCustomerToDb(customer_id,formData){
    let u = new Customer();
    if(formData.hasOwnProperty('id') && formData.id){
        u.id = formData.id;
    }
    u.name = formData.name;
    u.email = formData.email;
    u.phone1 = formData.phone1;
    u.phone2 = formData.phone2;
    //return u.save({transaction:false});
  }

  saveCustomerToApi(customer_id,formData){
    if( customer_id !=0 ){
      return this.api.processWithToken('/customers/'+customer_id,formData,'put').toPromise();
    }
    return this.api.processWithToken('/customers',formData).toPromise();
  }

  public getCustomersFromDb(){
   // return getRepository(Customer).find();
  }

}