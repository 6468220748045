import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { KeypadComponent } from '../keypad/keypad.component';

@Component({
  selector: 'app-edit-cart-product',
  templateUrl: './edit-cart-product.component.html',
  styleUrls: ['./edit-cart-product.component.scss'],
})
export class EditCartProductComponent implements OnInit {
  @Input() p:any;
  constructor(private modalCtrl:ModalController) { }

  ngOnInit() {
    if(this.p && this.p.qty ==0){
      this.p.qty = 1;
    }
  }
  confirm(){
    this.modalCtrl.dismiss({product:this.p});
  }
  dismiss(){
    this.modalCtrl.dismiss();
  }
  async openKeyPad(fieldName){
    let modal = await this.modalCtrl.create({
      component:KeypadComponent,
      componentProps:{
        "fieldName":fieldName
      }
    });
    modal.onDidDismiss().then((res:any)=>{
      if(res.data && res.data.value){
        if(fieldName == 'price'){
          this.p.price = res.data.value;
        }
        if(fieldName == 'qty'){
          this.p.qty = res.data.value;
        }
      }
    });
    return await modal.present();
  }

}
