export class Settings{

    id: number;
  
    token:string                     =   "";
    
    endpoint:string                  =   "http://192.168.1.109/api";
     
    mode:string                      =   "online";
     
    direction:string                 =   "rtl";
     
    language:string                  =   "fa";
     
    companyName:string               =   "شرکت تجارتی عبدالمبین کشور لمتد";
     
    receiptPrinter:boolean           =   false;
    
    dashboardType:number             = 1;
     
    currency:string                  = 'AFN';
   
    setSetting(setting){
        if(setting){
            this.endpoint = setting.endpoint;
            this.mode = setting.mode;
            this.direction = setting.direction;
            this.language = setting.language;
            this.companyName = setting.companyName;
            this.receiptPrinter = setting.receiptPrinter;
            this.dashboardType = setting.dashboardType;
            this.currency = setting.currency;
        }
       
       return this;
    }
   

    getDirection(){
        return this.direction;
    }

    isOnlineMode(){
        return this.mode == 'online';
    }
    
}