import { Component, OnInit } from '@angular/core';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { Api } from '../services/Api';
import { Cart } from '../services/Cart';
import * as moment from 'jalali-moment';
import { ActionSheetController } from '@ionic/angular';
import { AccountPaymentComponent } from '../customers/account-payment/account-payment.component';
import { LineItem } from '../models/LineItem';
import { Router } from '@angular/router';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  public invoices:Array<any>=[];
  public customer_id:any=0;
  public startDate:any=0;
  public startDateValue:any=0;
  public endDate:any=0;
  public endDateValue:any=0;
  public invoiceId:any="";
  constructor(private translate:TranslateService,private api:Api,public Cart:Cart,private platform:Platform,public actionSheetController: ActionSheetController,private modalCtrl:ModalController,private router:Router) {
    this.startDateValue = moment().format('YYYY-MM-DDTHH:mmZ');
    this.endDateValue   = moment().add(1,'day').format('YYYY-MM-DDTHH:mmZ');
  }

  ngOnInit() {
  
 
  }
  ionViewDidEnter(){
    this.platform.ready().then(()=>{
      this.getInvoices();
    });

  }
  
  getInvoices(){
    this.api.processWithToken("/invoices",{
      customer_id:this.customer_id,
      startDate:this.startDate,
      endDate:this.endDate,
      invoiceId:this.invoiceId,
    },'get').subscribe((res:any)=>{
      this.invoices= res;
    },()=>{
        this.translate.get('messages.problem').subscribe((val)=>{
          alert(val);
        });
    })
  }
  dateSelected(event,type){
    let selectedDate = moment(event.selectedDate).format('YYYY-MM-DD');
  
    if(type=='start'){
      this.startDate = selectedDate;
    }
    if(type=='end'){
      this.endDate = selectedDate;
    }
   
  }
  selectCustomer(){
    this.api.selectCustomer().then(()=>{
      this.customer_id=this.api.activeCart.selectedCustomer.id;
      this.getInvoices();
    });
  }

  async presentActionSheet(invoice) {
    
    this.translate.get('ordersActions').subscribe(async (ordersActionsLabel)=>{
      let buttons = [
        {
            text: ordersActionsLabel.print,
            icon: 'print',
            handler: () => {
              this.printInvoice(invoice.reference_no);
            }
        }, 
        {
          text: ordersActionsLabel.edit,
          icon: 'create',
          handler: () => {
            this.editInvoice(invoice.reference_no);
          }
      }, 
        {
          text: ordersActionsLabel.cancel,
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            this.cancelInvoice(invoice.reference_no);
          }
        }
      
      ];
  
      if(invoice.status==0){
        buttons.unshift({
          text:ordersActionsLabel.receivePayment,
          icon:'cash',
          handler:()=>{
            this.openPaymentModal(invoice);
          }
        });
 
      }
      const actionSheet = await this.actionSheetController.create({
        header: ordersActionsLabel.title,
        cssClass: 'my-custom-class',
        buttons: buttons
      });
      await actionSheet.present();
  
      const { role } = await actionSheet.onDidDismiss();

    });
   
  }

  cancelInvoice(reference_no){
    this.translate.get('messages.delete_confirmation').subscribe((message)=>{
      let c = confirm(message);
      if(c){
        this.api.processWithToken("/refundInvoice/"+reference_no,{},'GET').subscribe(()=>{
          this.translate.get('messages.delete_confirmation').subscribe((message)=>{
            this.getInvoices();
          });
        });
      }
    });
     
  }

  printInvoice(reference_no){
    this.api.printInvoice(reference_no);
  }

  async openPaymentModal(invoice){
    let modal = await this.modalCtrl.create({
      component:AccountPaymentComponent,
      componentProps:{
        "customer_id":invoice.customer_id,
        "reference_no":invoice.reference_no,
        "amount":invoice.total
      }
    });
    modal.onDidDismiss().then(res=>{
      if(res.data && res.data.hasOwnProperty('payment')){
        this.getInvoices();
      }
    });
    return modal.present();
  }

  editInvoice(reference_no){
    this.api.processWithToken("/toCart/"+reference_no,{},'GET').subscribe((invoice:any)=>{
      this.api.activeCart.reset();
     console.log('INVOICE',invoice);

      for( let i of invoice.items){
        let line = new LineItem(i.product_id,i.description,i.description,i.price,i.unit,i.type,i.barcode);
        line.qty = parseFloat(i.qty);
        this.api.activeCart.addToCart(line);
        this.api.activeCart.reference_no = invoice.reference_no;
      }
      this.api.activeCart.selectedCustomer = invoice.customer;
      console.log("ACTIVE CAART",this.api.activeCart.selectedCustomer);
      this.router.navigateByUrl("/");
    });
  }
}
