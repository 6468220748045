import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

import { Platform } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { Api } from "./Api";
@Injectable({
    providedIn: "root"
})
export class LoginGuard implements CanActivate {
    private storage:Storage;
    constructor(private router: Router, private storageService:Storage,private api:Api,private platform:Platform) {

     }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.storage = await this.storageService.create();
        let ready = await this.platform.ready();
      
        if(ready){
            const data          = await this.storage.get("settings");
                try{
                  
                    if(!data){
                        throw new Error('NOT SETTINGS');
                    }
                    try{
                        const isLoggedIn    = await this.api.isLoggedIn();
                        return true;
                    }catch(err){
                        this.router.navigateByUrl('/login');
                        return false;
                    }
                    

                }
                catch(err){
                    alert('error'+JSON.stringify(err));
                    this.router.navigateByUrl('/settings');
                    return false;
                }
                
        };
        return false;
        
    }
}