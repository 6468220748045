import { Component, OnInit } from '@angular/core';
import { Api } from '../services/Api';

@Component({
  selector: 'app-cart-info',
  templateUrl: './cart-info.component.html',
  styleUrls: ['./cart-info.component.scss'],
})
export class CartInfoComponent implements OnInit {

  constructor(public api:Api) { }

  ngOnInit() {}

}
