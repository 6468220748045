import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ProductService } from '../services/ProductService';

@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.scss'],
})
export class SearchProductComponent implements OnInit {
  public searchForm=new FormGroup({search:new FormControl('')});
  constructor(private modalCtrl:ModalController,public productService:ProductService) { }

  ngOnInit() {
    this.productService.setProducts();
  }

  selectProduct(product){
    this.modalCtrl.dismiss(product);
  }
}
