import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import { Product } from './models/Product';
import { Api } from './services/Api';
import { Dari } from './translations/dari';
import {English} from './translations/english';
import { Customer } from './models/Customer';

import {Settings} from './models/Settings';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public direction="rtl";
  public appPages = [
    { title: 'dashboard', url: '/dashboard', icon: 'calculator' },
    { title: 'customers', url: '/customers', icon: 'people' },
    { title: 'products', url: '/products', icon: 'pricetags' },
    { title: 'orders', url: '/orders', icon: 'bar-chart' },
   // { title: 'poolTables', url: '/poolTables', icon: 'albums' },

    { title: 'users', url: '/users', icon: 'person' },
    { title: 'settings', url: '/settings', icon: 'build' },
    { title: 'login', url: '/login', icon: 'exit' },
  ];
  public labels = [];
  constructor(private router: Router,private translate:TranslateService,public api:Api,private platform:Platform) {
    translate.setTranslation('en', English.words);
    translate.setTranslation('fa', Dari.words);

  }

  ngOnInit(){

    this.platform.ready().then((settings)=>{
      setTimeout(()=>{
        
        if(this.api.settings && this.api.settings.language == 'en'){
          this.translate.use('en');
        }else{
          this.translate.use('fa');
        }
      },3000);
    
      /*
      setTimeout(()=>{
        this.api.isLoggedIn().subscribe((response)=>{
          this.router.navigate(['/dashboard']);
          console.log('ABC',response);
        },(error:any)=>{
            this.router.navigate(['/login']);
        });
      },2000);
     
    */
    });
  }
  ionViewDidLoad() {
    alert('ENTERED');
  }

}
