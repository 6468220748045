import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Api } from '../services/Api';
import {Settings} from '../models/Settings';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { SettingsService } from '../services/SettingsService';
import { UserService } from '../services/UserService';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private settings:Settings;
  public form = new FormGroup({
    username:  new FormControl(''),
    password:  new FormControl('',Validators.required),
  });
  
  constructor(private api:Api,private platform:Platform,private router: Router,private settingService:SettingsService,private userService:UserService) { 
      

  }

  ngOnInit() {
    this.platform.ready().then(async ()=>{
      this.router.navigate(['/dashboard']);
      let isLoggedIn = this.api.isLoggedIn();
          if(isLoggedIn){
           // this.router.navigate(['/dashboard']);
          }
    });

  }

  async login(){
    let isOnline=await this.settingService.isOnline();

    if(isOnline){
      this.api.process('/users/login',this.form.value).subscribe((response:any)=>{
        this.api.setToken(response.token);
        this.router.navigate(['/dashboard']);
      });
    }
   
  }
}



