
export class English{
    public static words={
        appName:'SALES',
        welcome_message:"Welcome",
        supportContactNumber:'SUPPORT: +93 70 230 2030',
        products:'Products',
        poolTables:'Pool Tables',
        users:"Users",
        productInfo:"Product information",
        save:'Save',
        dari:'Dari',
        pashto:'Pashto',
        english:'English',
        settings:"Settings",
        select_language:'Please select a language',
        app_mode:"Please select application mode",
        online:"Online",
        offline:'Offline',
        end_point_address:"Server Address",
        enter_end_point_address:"Enter the server address http:// or https://....",
        messages:{
            invalidUrl:"Please enter a valid url for the server!",
        },
        product:{
            name:"Name",
            price:"Price"
        },
        cash:"Cash",
        account:"Account",
        payment_type:"Payment Type",
        dashboard:"Dashboard",
        qty:"Qty",
        price:'Price',
        description:'Description',
        grandTotal:'Grand Total',
        tenderedAmount:'Tendered Total',
        change:"Change",
        confirm:"Process",
        sale_confirmation_message:"Are you sure you want to make this transaction?",
        customers:"Customers",
        customer:{
            info:"Customer Information",
            name:"Name",
            phone1:"Phone 1",
            phone2:"Phone 2",
            email:"Email",

        },
        ordersActions:{
            print:"Print",
            cancel:"Cancel",
            receivePayment:"Receive payment"
        },
        cartItemActions:{
            delete:"Delete",
            edit:"Edit",
        },
        companyName:"Company Name"
    }
}