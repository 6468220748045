import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'jalali-moment';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  @Input() value:any="";
  public dateForm:FormGroup;
  public today:any;
  public years=[];
  public months=[];
  public days=[];
  @Input() monthNames=[
    'حمل',
    'ثور',
    'جوزا',
    'سرطان',
    'اسد',
    'سنبله',
    'میزان',
    'عقرب',
    'قوس',
    'جدی',
    'دلو',
    'حوت',
  ];
  @Output() dateSelectedEvent = new EventEmitter<string>();

  constructor(private translate:TranslateService) { 
    this.dateForm=new FormGroup({
      selectedDate:new FormControl(''),
    });
    this.dateForm.valueChanges.subscribe(()=>{
      console.log('DATE',this.dateForm.value);
      this.dateSelectedEvent.emit(this.dateForm.value);
    });
  
   
    
  }

  ngOnInit() {
    this.translate.get('name').subscribe(()=>{
      console.log('IS SHAMSI',this.isShamsiDate());
      if(this.isShamsiDate()){
        this.today = moment().locale('fa');
        this.value = moment.from(this.value,'en','YYYY-MM-DDTHH:mmZ').locale('fa').format('YYYY-MM-DDTHH:mmZ');
        this.dateForm.get('selectedDate').setValue(this.value);
      }else{
        this.today = moment();
        this.value = moment(this.value,'YYYY-MM-DDTHH:mmZ').locale('en').format('YYYY-MM-DDTHH:mmZ');
       this.dateForm.get('selectedDate').setValue(this.value);
      }
    })
 
    console.log('THE VALUE',this.value);
      this.setYears();
      this.setMonths();
      this.setDays();
  }


  private setYears(){
    this.years=[];
    let thisYear = this.today.format('YYYY');
    console.log('RUNNING',thisYear);
    let lastYears= (thisYear -10);
    while( thisYear >  lastYears){
      this.years.push(thisYear);
      thisYear= thisYear-1;
    
    }
  }
  private setMonths(){
    this.months=[];
    let month =1;
    while(month < 13){
      this.months.push( this.getMonthNameByNumber(month));
      month++;
    }
  }

  getMonthNameByNumber(i){
    return this.monthNames[i-1];
  }

  setDays(){
    this.days=[];
      let day=1;
      while(day < 32){
        this.days.push(day);
        day = day+1;
      }
    
    
  }
  public isShamsiDate(){
    return this.translate.currentLang!='en';
  }
}
