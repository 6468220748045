
export class Product {


      id:number;

  
      name:string;

 
      description:string;

   
      price:number;

    
      unit:string;


      type:string;


      barcode:string;
      available:number;

    public isChecked    = false;
    public isStockable  = true;
    public current_stock =  0;
    
    constructor(id:number,name:string,description:string="",price:number,unit:string,type:string,barcode:string="",available:number=0){
      this.id = id;
      this.name=name;
      this.description = description;
      this.price= price;
      this.unit = unit;
      this.type=type;
      this.barcode=barcode;
      this.available = available;
      
    }

  }