export class Customer{
    
      id:string;

      name:string;

      email:string;

      phone1:string;

      phone2:string;
  }