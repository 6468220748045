import { Component, Input, OnInit } from '@angular/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EditCartProductComponent } from '../edit-cart-product/edit-cart-product.component';
import { ReceiptComponent } from '../receipt/receipt.component';
import { Api } from '../services/Api';
import { Cart } from '../services/Cart';

@Component({
  selector: 'app-cartlist',
  templateUrl: './cartlist.component.html',
  styleUrls: ['./cartlist.component.scss'],
})
export class CartlistComponent implements OnInit {
  @Input() showPaymentType=false;
  constructor(public api:Api,private actionCtrl:ActionSheetController,private translate:TranslateService,private modalCtrl:ModalController) { }
  
  ngOnInit() {}
  async openAction(p,index) {
    this.translate.get('cartItemActions').subscribe(async (action)=>{
    const actionSheet = await this.actionCtrl.create({
      buttons: [{
        text: action.delete,
        role: 'destructive',
        icon: 'trash',
        handler: () => {
          this.api.activeCart.removeProduct(index);
        }
      }, {
        text: action.edit,
        icon: 'create-outline',
        handler: () => {
          this.editAndAdd(p);
        }
      }
    ]
    });
  
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  })  
  }

  async editAndAdd(p){
    let modal = await this.modalCtrl.create({
      component:EditCartProductComponent,
      componentProps:{
        p:p
      }
    });

    modal.onDidDismiss().then((res:any)=>{
      if(res.data && res.data.hasOwnProperty('product')){
        console.log("THE PRODUCT",res.data.product);
        this.api.activeCart.addToCart(res.data.product,false);
      }
    });
    
    return await modal.present();
  }
  


}
