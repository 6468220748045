import { Component, OnInit, Input } from '@angular/core';
import { ModalController ,NavController } from '@ionic/angular';

import { Router } from '@angular/router';


@Component({
  selector: 'app-keypad',
  templateUrl: './keypad.component.html',
  styleUrls: ['./keypad.component.scss'],
})
export class KeypadComponent implements OnInit { 
  public value:any="0.00";
  @Input() fieldName:string="";
  constructor(private modalCtrl:ModalController) {
    
  }

  ngOnInit() {}

  trigger(val){
    if(this.value == '0.00'){
      this.value="";
    }
    this.value = this.value + val;
  }

  backspace(){
    let val     = this.value.split("");
    val.pop();
    this.value = val.join("");
    if(val.length == 0){
      this.value = "0.00";
    }
  }
  confirm(){
    this.modalCtrl.dismiss({value:this.value});
  }


}
