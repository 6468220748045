//import { Customer } from './Customer';
import { LineItem } from '../models/LineItem';
import { Injectable} from "@angular/core";
import { Settings } from '../models/Settings';
import { ModalController ,NavController } from '@ionic/angular';
import { CustomerModalComponent } from "../customer-modal/customer-modal.component";
import { cloneDeep } from "lodash";
export class Cart{
    public  selectedCustomer:any;
    //line Items
    public products:Array<LineItem>=[];
    public reference_no:string="";
    public paymentType="cash";
    public subTotal:number      = 0.00; 
    public grandTotal:number    = 0.00;
    public totalDiscount:number = 0.00;
    public totalTax:number      = 0.00;
    public tendered_amount:number=0;
    public change:number = 0;
    constructor(){
        if( !this.selectedCustomer ){
            this.selectedCustomer = this.getDefaultCustomer();
        }
        console.log('CART CONSTRUCTOR',this.selectedCustomer);
    }
    public getDefaultCustomer(){    
        let c = {id:1,name:"."};
            return c;
    
    }

    public addToCart(product:LineItem,increment?:boolean){
        if(!product.id){
            return false;
        }
        console.log('ADDING TO THE CART',product.qty);
        product = cloneDeep(product);
        if(increment){
           let cartProduct = this.getProductById(product.id);
           if(cartProduct){
               cartProduct.qty = cartProduct.qty+1;
               product = cartProduct;
           }else{
               product.increase();
           }
        }
        
       
        if( this.productExists(product) == false){
            this.products.unshift(product);
        }else{
            let index=this.products.findIndex(p=>p.id==product.id);
            this.removeProduct(index);
            this.products.unshift(product);
        }
        
        this.updateCart();
    }

    public removeProduct( key ){
        this.products.splice(key,1);
        this.updateCart();
    }
    public productExists(product:LineItem){
        for( let p of this.products){
            if( p.id === product.id){
                return true;
            }
        }

        return false;
    }

    public updateCart(){
        this.resetTotals();
        for( let p of this.products ){
            this.subTotal       = this.subTotal     + p.subTotal();
            this.grandTotal     = this.grandTotal   + p.total();
            this.totalTax       = this.totalTax     + p.tax();
            this.totalDiscount  = this.totalDiscount + p.discountAmount();
        }

        this.subTotal       = Number( this.subTotal.toFixed(2) ) ;
        this.grandTotal     = Number( this.grandTotal.toFixed(2) ) ;
        this.totalTax       = Number( this.totalTax.toFixed(2) ) ;
        this.totalDiscount  = Number( this.totalDiscount.toFixed(2));
    }

    public resetTotals(){
        this.subTotal       = 0.00;
        this.grandTotal     = 0.00 ;
        this.totalTax       = 0.00;
        this.totalDiscount  = 0.00;
        this.change  = 0.00;
        this.tendered_amount  = 0.00;

    }

    public updateChange(){
        this.change = this.tendered_amount - this.grandTotal;

        this.change = Number( this.change.toFixed(2));
   

    }

    public reset(){
        this.products =[];
        this.resetTotals();
        this.selectedCustomer = this.getDefaultCustomer();
        console.log('RESET CART');
    }


    
    getCartItems(){
        return this.products.map((p)=>{
            let l = Object.assign({},p);
            l['description']= l.name;
            l['product_id']= l.id;
            return l;
        });
    }
    setPaymentType(type){
        this.paymentType = type;
      }

    getProductById(id){
        let index=this.products.findIndex(p=>p.id==id);

        return this.products[index];
    }
    
}   