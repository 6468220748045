import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Api } from '../services/Api';
import { Cart } from '../services/Cart';
import { ProductService } from '../services/ProductService';

@Component({
  selector: 'app-product-selection-dashboard',
  templateUrl: './product-selection-dashboard.component.html',
  styleUrls: ['./product-selection-dashboard.component.scss'],
})
export class ProductSelectionDashboardComponent implements OnInit {
  @Input() searchForm:FormGroup;
  constructor(public cart:Cart,public api:Api,public productService:ProductService,private router:Router) { }

  ngOnInit() {}
  charge(){
    this.router.navigate(['/charge'])
  }

}
