import { Component, OnInit } from '@angular/core';
import { Cart } from "../services/Cart";
import { NavController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';
declare var sunmiInnerPrinter: any;
import {Settings} from '../models/Settings';

import { ModalController } from '@ionic/angular';
import { Api } from '../services/Api';
import { TranslateService } from '@ngx-translate/core';
import { ProductsPage } from '../products/products.page';
import { Product } from '../models/Product';
import { LineItem } from '../models/LineItem';
@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit {
  public invoice:any;

  constructor(private navParams:NavParams,private navCtrl:NavController,private router:Router,private modalCtrl:ModalController,public api:Api) { 
    this.invoice = navParams.get('invoice');
    console.log('FIRST INVOICE2',this.invoice);
    this.invoice.line_items=this.invoice.line_items.map((l:any)=>{
      let line=new LineItem(l.id,l.description,l.description,l.price,'','','');
          line.qty = l.qty;
          line.discount = l.discount;
          return line;
    });
    console.log('INVOICE',this.invoice);
  }

  ngOnInit() {}
  
  async print(){
    console.log('PRINTING');
    try{
      
    //alert('printing');
   // alert('printing 123');
   
    await sunmiInnerPrinter.printerInit();
    await  sunmiInnerPrinter.setAlignment(1);
   // await  sunmiInnerPrinter.printBitmap(Settings.temp.company.logo,300,200);
    await sunmiInnerPrinter.setFontSize(35);
    await sunmiInnerPrinter.lineWrap(1);
   // await sunmiInnerPrinter.printString(Settings.temp.company.welcomeMessage);
    await sunmiInnerPrinter.setFontSize(32);
    await sunmiInnerPrinter.setAlignment(0);
    await sunmiInnerPrinter.lineWrap(1);
    await sunmiInnerPrinter.printOriginalText('نام مشتری: ' + this.invoice.customer_name);
    await sunmiInnerPrinter.lineWrap(2);
    await sunmiInnerPrinter.printOriginalText('شماره بل: ' + this.invoice.id);
    await sunmiInnerPrinter.setAlignment(2);
    await sunmiInnerPrinter.printOriginalText('تاریخ: ' + this.invoice.shami_date_time);
    await sunmiInnerPrinter.lineWrap(2);
        for(let i=0;i < this.invoice.line_items.length;i++){
          let p = this.invoice.line_items[i];
          await sunmiInnerPrinter.setAlignment(0);
          await sunmiInnerPrinter.printOriginalText(p.description);
          await sunmiInnerPrinter.lineWrap(1);
          await sunmiInnerPrinter.printString(p.qty+" x "+p.price);
          await sunmiInnerPrinter.setAlignment(2);
          await sunmiInnerPrinter.lineWrap(1);
          await sunmiInnerPrinter.printOriginalText(p.total()+" AFN");
          await sunmiInnerPrinter.lineWrap(1);
        }
        await sunmiInnerPrinter.setAlignment(1);
        await sunmiInnerPrinter.setFontSize(35);
        await sunmiInnerPrinter.lineWrap(1);
        await sunmiInnerPrinter.printString("مجموعه افغانی  "+this.invoice.total);
        await sunmiInnerPrinter.lineWrap(1);
        await sunmiInnerPrinter.printString("پول پرداخت شده  "+this.invoice.tendered_amount);
        await sunmiInnerPrinter.lineWrap(1);
        await sunmiInnerPrinter.printString("بقایه "+this.invoice.change);
        await sunmiInnerPrinter.lineWrap(2);
        await sunmiInnerPrinter.printString("نوع پرداخت "+this.invoice.payment_type);
        await sunmiInnerPrinter.lineWrap(2);
        this.cutPaper();
  
    }catch(e){
      alert(e.message);
    }
    
    this.modalCtrl.dismiss();
    
  }

  bufferToBase64(buf) {
    var binstr = Array.prototype.map.call(buf, function (ch) {
        return String.fromCharCode(ch);
    }).join('');
    return btoa(binstr);
  }
  cutPaper(){
    let cutpaper = [0x1d, 0x56, 0x42, 0x00];
    let cutpaperUint8 = new Uint8Array(cutpaper);
    let cutpaperBase64 = this.bufferToBase64(cutpaperUint8);
    sunmiInnerPrinter.sendRAWData(cutpaperBase64);
  }
  goToHome(){
 
    this.modalCtrl.dismiss();

  }
  resetCart(){
 
  }

}
